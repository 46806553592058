// extracted by mini-css-extract-plugin
export var lbContainerOuter = "x_t0";
export var lbContainerInner = "x_t1";
export var movingForwards = "x_t2";
export var movingForwardsOther = "x_t3";
export var movingBackwards = "x_t4";
export var movingBackwardsOther = "x_t5";
export var lbImage = "x_t6";
export var lbOtherImage = "x_t7";
export var prevButton = "x_t8";
export var nextButton = "x_t9";
export var closing = "x_vb";
export var appear = "x_vc";