// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "v_tK d_bD";
export var storyRowWrapper = "v_hx d_hx d_bK";
export var storyLeftWrapper = "v_tL d_bz d_bP";
export var storyWrapperFull = "v_tM d_cD";
export var storyWrapperFullLeft = "v_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "v_mv d_hy";
export var storyLeftWrapperCenter = "v_tN d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "v_tP d_hF";
export var storyHeader = "v_tQ d_hD d_w d_cs";
export var storyHeaderCenter = "v_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "v_hB d_hB d_by d_dw";
export var storyBtnWrapper = "v_tR d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "v_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "v_qy d_fg d_Z";
export var imageWrapperFull = "v_tS d_w d_H d_bf d_Z";
export var SubtitleSmall = "v_qd q_qd q_q7 q_rk";
export var SubtitleNormal = "v_qf q_qf q_q7 q_rl";
export var SubtitleLarge = "v_qg q_qg q_q7 q_rm";
export var textLeft = "v_dv";
export var textCenter = "v_dw";
export var textRight = "v_dx";